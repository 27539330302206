import React from "react";
import Header from "src/components/Header";


class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-20">
            <h1>Stress en diarree</h1>
            <div className="img">
              <img
                src="/assets/files/pages/stress.jpeg"
                width="701"
                height="289"
                alt="Stress en diarree"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Waarom stress diarree veroorzaakt</h2>
              </div>
              <div className="nine columns">
                <p>
                  Sommige mensen krijgen&nbsp;
                  <strong>diarree door stress</strong> op het werk of door
                  sociale oorzaken. Stresssituaties zoals een{" "}
                  <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                    hoge werkdruk
                  </a>
                  , sollicitatiegesprekken,{" "}
                  <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                    examens
                  </a>
                  , of optredens voor een groot publiek zijn vaak de oorzaak van
                  de verstoring van het natuurlijke ritme van het
                  spijsverteringsstelsel
                </p>
              </div>
            </div>
            <p>
              In geval van stress produceert je lichaam bepaalde hormonen.&nbsp;
              Deze hormonen kunnen leiden tot een verhoogde afscheiding van
              vocht en mineralen, alsook slijm in de darm. De opname van vocht
              en mineralen uit de darm in het lichaam wordt dan weer geremd. De
              ontlasting wordt wateriger en de drang tot stoelgang frequenter.
            </p>
          </div>
        </div>
         <footer className="footer">
        <div className="row">
          <div className="three columns">
            <ul>
              <li className="first last">
                <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
                <ul>
                  <li className="first last">
                    <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                      De werkzame stof: loperamide
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className="first last">
                <a href="/imodium-behandelen-diarree/">
                  IMODIUM® Geneesmiddelen
                </a>
                <ul>
                  <li className="first">
                    <a href="/imodium-behandelen-diarree/imodium-instant/">
                      IMODIUM® Instant Smelttablet
                    </a>
                  </li>
                  <li>
                    <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                      IMODIUM® Capsules
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul>
              <li className="first last">
                <a href="/feiten-over-diarree/">Feiten over diarree</a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                      Tips voor behandeling
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                      Oorzaken van diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/stress-en-diarree/">
                      Stress en diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                    Hoge werkdruk
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                    Examensituaties
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/buikgriep/">
                      Virale of bacteriële infectie
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                      Diarree tijdens de menstruatie
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/chronische-diarree/">
                      Chronische Diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/eenartsraadplegen/">
                      Een arts raadplegen?
                    </a>
                  </li>
                  <li className="">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                      Gezonde eetgewoonten
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul></ul>
            <ul>
              <li className="first last">
                <a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                  <li className="first">
                    <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                      Reizigersdiarree vermijden
                    </a>
                  </li>
                  <li>
                    <a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">
                      Reizigersdiarree behandelen
                    </a>
                  </li>
                  <li>
                    <a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul>
              <li className="first">
                <a href="/diarree-bij-kinderen/">Diarree bij kinderen</a>
              </li>
              <li className="here parent-here">
                <a href="/woordenlijst/">Woordenlijst diarree</a>
              </li>
              <li className="last">
                <a href="/veelgestelde-vragen-diarree/">FAQ</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="twelve columns">
            <nav className="nav">
              <ol className="uilist-hor">
                <li className="first">
                  <a href="/sitemap/">Sitemap</a>
                </li>
                <li>
                  <a href="/juridische-kennisgeving/">
                    Juridische Kennisgeving
                  </a>
                </li>
                <li>
                  <a href="/privacybeleid/">Privacybeleid</a>
                </li>
                <li>
                  <a href="/cookiebeleid/">Cookiebeleid</a>
                </li>
                <li className="last">
                  <a href="/contact/">Contact</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="disclaimer">
          <div className="row">
            <div className="twelve columns">
              <p>
                © Johnson &amp; Johnson Consumer B.V. 2010-2017. KOAG nr
                33-1214-1700. Laatst bijgewerkt op: 18 december 2014.{" "}
              </p>
              <p>
                Deze site is gepubliceerd door Johnson &amp; Johnson Consumer
                B.V., die als enige verantwoordelijk is voor de inhoud van de
                site. De site is bedoeld voor een Nederlands publiek.
                <br />
                <br />
                IMODIUM® bevat loperamide voor het verlichten van diarree. Lees
                voor gebruik de bijsluiter. IMODIUM® mag niet gebruikt worden
                bij kinderen jonger dan 8 jaar. Tussen 2 en 8 jaar enkel Imodium
                orale drank gebruiken op medisch voorschrift. Niet gebruiken bij
                aanhoudende buikpijn, bij slijm of bloed in de ontlasting.
              </p>
            </div>
          </div>
        </div>
        <script src="/assets/js/custom.js" />
      </footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
